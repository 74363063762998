import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { PayPalButtonsComponentOptions } from '@paypal/paypal-js/types/components/buttons';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { PayPalPaymentCard } from './PayPalCheckoutCard';

interface Props {
  open: boolean;
  orderId: string | undefined;
  onClose(): void;
  onPaymentApprove?: PayPalButtonsComponentOptions['onApprove'];
}

export const PayPalPaymentModal: FC<Props> = ({
  open,
  orderId,
  onClose,
  onPaymentApprove,
}) => {
  const { t } = useTranslation('common');

  if (!open || !orderId) return null;

  return (
    <ContentModal
      titleLabel={t('pay-pal-payment-modal.title')}
      cancelLabel={t('pay-pal-payment-modal.cancel')}
      isOpen={open}
      onCancel={onClose}
      minWidth="350px"
    >
      <Stack mb={1}>
        <PayPalPaymentCard
          onPaymentApprove={onPaymentApprove}
          orderId={orderId}
          onClose={onClose}
        />
      </Stack>
    </ContentModal>
  );
};
