import React, { FC, useState } from 'react';

import { SupportedPaymentTypes } from '@lib/payment/types';
import { Stack } from '@mui/material';

import { PaymentSelectOption } from '../types';
import { PaymentSelectBox } from './PaymentSelectBox';

interface Props {
  options: PaymentSelectOption[];
  initialValue: SupportedPaymentTypes;
  onSelect: (selectedId: SupportedPaymentTypes) => void;
}

export const PaymentSelect: FC<Props> = ({
  options,
  initialValue,
  onSelect,
}) => {
  const [selectedId, selectId] = useState<SupportedPaymentTypes>(initialValue);

  return (
    <Stack spacing={1} pt={2}>
      {options.map((option) => (
        <PaymentSelectBox
          key={option.id}
          onClick={handleSelect}
          option={option}
          selectedId={selectedId}
        />
      ))}
    </Stack>
  );

  function handleSelect(id: SupportedPaymentTypes): void {
    onSelect(id);
    selectId(id);
  }
};
