import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';

import { getEnv } from '@lib/env/getEnv';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Button } from '@ui/buttons/Button';
import { ContentModal } from '@ui/content-modal/ContentModal';

import { ICardValidation, IPaymentSession, IRyft } from './types';
import { PriceFormat } from '@ui/price/PriceFormat';
import { Box } from '@mui/material';

interface Props {
  onClose: () => void;
  open: boolean;
  totalUpfront: string | number;
  currency: string;
  clientSessionId?: string;
  onCheckPaymentStatus: () => void;
  paymentType?: 'Unscheduled' | undefined;
}

declare const Ryft: IRyft;

export const RyftPaymentModal: React.FC<Props> = ({
  onClose,
  open,
  clientSessionId,
  totalUpfront,
  onCheckPaymentStatus,
  paymentType,
  currency,
}) => {
  const { t } = useTranslation('common');
  const [payButtonDisabled, setPayButtonDisabled] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const { tenant } = useTenantProps();
  const ryftPublicKey = tenant.config.setup.ryftPublicKey;
  const env = getEnv();

  useEffect(() => {
    if (open) {
      Ryft.init({
        clientSecret: clientSessionId,
        publicKey: ryftPublicKey[env.client.appEnv] ?? '',
        localisation: {
          cardNumberPlaceholder: t('ryft-payment-modal.card-number'),
        },
        ...(paymentType && { paymentType }),
      });
      Ryft.addEventHandler(
        'cardValidationChanged',
        (event: ICardValidation) => {
          setPayButtonDisabled(!event.isValid);
        },
      );
    }
  }, [clientSessionId, env.client.appEnv, open, paymentType, ryftPublicKey, t]);

  const roundedTotalUpfront = Number(totalUpfront);

  return (
    <ContentModal
      titleLabel={t('ryft-payment-modal.title')}
      isOpen={open}
      onCancel={onClose}
      minWidth="40vw"
      keepMounted={true}
    >
      <form id="ryft-pay-form" onSubmit={(e) => onSubmit(e)}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button
            type="submit"
            variant="contained"
            disabled={payButtonDisabled}
            loading={isPaymentLoading}
            id="pay-btn"
          >
            {t('ryft-payment-modal.pay')}{' '}
            <PriceFormat price={roundedTotalUpfront} currency={currency} />
          </Button>
          <Button onClick={onClose}>{t('ryft-payment-modal.cancel')}</Button>
        </Box>
      </form>
    </ContentModal>
  );

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      setPayButtonDisabled(true);
      setIsPaymentLoading(true);
      const paymentSession: IPaymentSession = await Ryft.attemptPayment({
        clientSecret: clientSessionId,
      });
      const status: string = paymentSession.status;
      const successArr: string[] = ['Approved', 'Captured', 'PendingAction'];
      if (successArr.includes(status)) {
        toast.success(t(`ryft-payment-modal.success-messages.${status}`));
        onClose();
        onCheckPaymentStatus();
      } else {
        toast.error(
          paymentSession.error ||
            t(`ryft-payment-modal.errors.${paymentSession.lastError}`),
        );
      }
    } catch (err) {
      toast.error(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (err as any) || t('ryft-payment-modal.errors.payment-failed'),
      );
    } finally {
      setPayButtonDisabled(false);
      setIsPaymentLoading(false);
    }
  }
};
