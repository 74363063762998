// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  banner: dynamic(() => import('./Banner')),
  card: dynamic(() => import('./Card')),
  carousel: dynamic(() => import('./Carousel')),
  drawer_menu_item_order: dynamic(() => import('./DrawerMenuItemOrder')),
  frequently_asked_questions: dynamic(
    () => import('./FrequentlyAskedQuestions'),
  ),
  header_menu_item_order: dynamic(() => import('./HeaderMenuItemOrder')),
  link_list: dynamic(() => import('./LinkList')),
  modal: dynamic(() => import('./Modal')),
  nav_item_category: dynamic(() => import('./NavItemCategory')),
  product_addons: dynamic(() => import('./ProductAddons')),
  product_list: dynamic(() => import('./ProductList')),
  rich_text: dynamic(() => import('./RichText')),
  scroll_to_line: dynamic(() => import('./ScrollToLine')),
  section_intro: dynamic(() => import('./SectionIntro')),
  tiles: dynamic(() => import('./Tiles')),
};
