import { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { getTickets } from '@api/ocb-digital/ticket/api';
import { Ticket } from '@api/ocb-digital/ticket/types';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SupportCardContent } from '@templates/home/home-auth/tickets/support-card/SupportCardContent';
import { Button } from '@ui/buttons/Button';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { Table } from '@ui/table/Table';
import { TableHeadCell } from '@ui/table/types';
import { useLocalisedDate } from '@lib/time';

export const Tickets: FC = () => {
  const { tenant } = useTenantProps();
  const { formatDateTimeTz } = useLocalisedDate();
  const {
    data: ticketsList,
    isLoading,
    refetch,
  } = useApiQuery(() => getTickets(tenant), { queryKey: [CACHE_KEYS.tickets] });
  const [isSupportTicketOpen, setIsSupportTicketOpen] = useState(false);
  const { t } = useTranslation();

  const tableHeaderCells: TableHeadCell<
    Pick<Ticket, 'identifier' | 'createdAt' | 'categoryName' | 'status'>
  >[] = [
    {
      id: 'identifier',
      formatLabel: () => t('home:auth.tickets.table.header.no'),
    },
    {
      id: 'createdAt',
      formatLabel: () => t('home:auth.tickets.table.header.date'),
      formatRowValue: (value) => formatDateTimeTz(value.createdAt),
    },
    {
      id: 'categoryName',
      formatLabel: () => t('home:auth.tickets.table.header.category'),
    },
    {
      id: 'status',
      formatLabel: () => t('home:auth.tickets.table.header.status'),
    },
  ];

  const rows = ticketsList?.map((ticket) => ({
    identifier: ticket.identifier,
    createdAt: ticket.createdAt,
    categoryName: ticket.categoryName,
    status: t(`home:auth.tickets.table.statuses.${ticket.status}`),
  }));

  return (
    <DefaultCard
      noBorder={false}
      title={t('home:auth.tickets.title')}
      button={
        <Button onClick={() => setIsSupportTicketOpen(true)}>
          {t('home:auth.tickets.newTicketButton')}
        </Button>
      }
    >
      <Table
        rows={rows ?? []}
        headCells={tableHeaderCells}
        orderBy="createdAt"
        order="desc"
        isLoading={isLoading}
      />
      <ContentModal
        isOpen={isSupportTicketOpen}
        titleLabel={t('home:auth.tickets.supportModal.title')}
        onCancel={onModalClose}
        showCloseModalIcon
      >
        <SupportCardContent onSubmit={onModalClose} />
      </ContentModal>
    </DefaultCard>
  );

  function onModalClose(): void {
    refetch();
    setIsSupportTicketOpen(false);
  }
};
