import { Box } from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { Button } from '@ui/buttons/Button';
import { PriceFormat } from '@ui/price/PriceFormat';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

interface Props {
  totalUpfront: string | number;
  callbackUrl: string;
  currency: string;
}

export const CheckoutForm: React.FC<Props> = ({
  totalUpfront,
  callbackUrl,
  currency,
}) => {
  const stripe = useStripe();
  const { t } = useTranslation();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    try {
      e.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: callbackUrl,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setIsLoading(false);

        throw new Error(error.message);
      } else {
        setIsLoading(false);

        throw new Error(t('common:errors.unexpected'));
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error(String(error));
      }
    }
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  const roundedTotalUpfront = Number(totalUpfront);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Box
        sx={{
          display: 'flex',
          my: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          loading={isLoading}
          type="submit"
        >
          {t('common:stripe-payment-modal.pay')}{' '}
          <PriceFormat price={roundedTotalUpfront} currency={currency} />
        </Button>
      </Box>
    </form>
  );
};
