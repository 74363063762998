import { api } from '@api/index';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { PriceFormat } from '@ui/price/PriceFormat';
import { Table } from '@ui/table/Table';
import { TableHeadCell } from '@ui/table/types';
import { useTranslation } from 'next-i18next';
import { FC, useMemo, useState } from 'react';
import { PaymentTableItems } from './types';
import { useLocalisedDate } from '@lib/time';
import { SYMBOLS } from '@lib/placeholders/constants';
import { DEFAULT_PAGE_SIZE } from '@ui/table/constants';

interface Props {
  accountId: string;
}

export const Payments: FC<Props> = ({ accountId }) => {
  const { tenant } = useTenantProps();
  const { formatDateTimeTz } = useLocalisedDate();
  const { t } = useTranslation('home');
  const [startRow, setStartRow] = useState(0);
  const [endRow, setEndRow] = useState(DEFAULT_PAGE_SIZE);
  const { data: payments, isFetching } = useApiQuery(fetchPayments, {
    enabled: !!accountId,
    queryKey: [
      CACHE_KEYS.customerPayments(
        accountId,
        startRow.toString(),
        endRow.toString(),
      ),
    ],
  });
  const tableHeaderCells: TableHeadCell<PaymentTableItems>[] = [
    {
      id: 'createdAt',
      formatLabel: () => t('auth.payments.table.header.createdAt'),
      formatRowValue: (value) =>
        value?.createdAt
          ? formatDateTimeTz(value?.createdAt)
          : SYMBOLS.emptyText,
    },
    {
      id: 'paymentReference',
      formatLabel: () => t('auth.payments.table.header.type'),
      formatRowValue: (value) =>
        value?.paymentReference?.type
          ? t(`auth.payments.table.types.${value?.paymentReference?.type}`)
          : SYMBOLS.emptyText,
    },
    {
      id: 'amount',
      formatLabel: () => t('auth.payments.table.header.amount'),
    },
    {
      id: 'status',
      formatLabel: () => t('auth.payments.table.header.status'),
      formatRowValue: (value) =>
        value?.status
          ? t(`auth.payments.table.statuses.${value?.status}`)
          : SYMBOLS.emptyText,
    },
  ];
  const rows = useMemo(() => {
    return payments?.content?.map((payment) => {
      return {
        createdAt: payment?.createdAt,
        paymentReference: payment?.paymentReference,
        amount: (
          <PriceFormat
            price={payment?.amount?.amount}
            currency={payment?.amount?.currency}
          />
        ),
        status: payment?.status,
      };
    });
  }, [payments?.content]);

  return (
    <DefaultCard title={t('auth.payments.title')} noBorder={false}>
      <Table
        rows={rows ?? []}
        headCells={tableHeaderCells}
        orderBy="createdAt"
        order="desc"
        isLoading={isFetching}
        count={payments?.lastRow}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </DefaultCard>
  );

  async function fetchPayments() {
    try {
      return await api.ocbDigital.customer.getCustomerPayments(
        tenant,
        accountId,
        {
          startRow: startRow.toString(),
          endRow: endRow.toString(),
          sort: 'createdAt,desc',
        },
      );
    } catch (error) {
      throw error;
    }
  }

  async function onChangeRowsPerPage(newLimit: number) {
    try {
      setStartRow(0);
      setEndRow(newLimit);
    } catch (error) {
      throw error;
    }
  }

  async function onChangePage(newPage: number, limit: number) {
    try {
      const startRow = Math.max((newPage - 1) * limit, 0);
      const endRow = Math.max(newPage * limit, 10);
      setStartRow(startRow);
      setEndRow(endRow);
    } catch (error) {
      throw error;
    }
  }
};
