import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { SubscriptionMainProductContent } from './SubscriptionPackageContent';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import {
  isProductNotExpired,
  isStatusActivePendingOrSuspended,
} from '@templates/common/helpers';
import { SubscriptionMainProductTitleNote } from './SubscriptionMainProductTitleNote';

export const SubscriptionMainProduct: FC = () => {
  const { t } = useTranslation();
  const {
    selectedSubscription,
    isLoading: isSubscriptionsLoading,
    isFetching: isSubscriptionsFetching,
    isError: isSubscriptionsError,
  } = useSubscriptions();
  const subscriptionMainProduct = isStatusActivePendingOrSuspended(
    selectedSubscription?.status,
  )
    ? selectedSubscription?.products.find(
        (product) =>
          isProductNotExpired(product) &&
          isStatusActivePendingOrSuspended(product.status),
      )
    : undefined;

  return (
    <DefaultCard
      title={t('home:auth.package.title')}
      titleNote={
        <SubscriptionMainProductTitleNote
          selectedSubscription={selectedSubscription}
        />
      }
      noBorder={false}
    >
      <SubscriptionMainProductContent
        isSubscriptionsFetching={isSubscriptionsFetching}
        isSubscriptionsLoading={isSubscriptionsLoading}
        isSubscriptionsError={isSubscriptionsError}
        subscriptionMainProduct={subscriptionMainProduct}
      />
    </DefaultCard>
  );
};
