import { PaymentProviderType } from './types';
import { REDIRECT_PAYMENT_PROVIDER_LIST } from './constants';

export const providerWithRedirect = (
  paymentProvider: PaymentProviderType | undefined,
) => {
  if (!paymentProvider) return false;

  return REDIRECT_PAYMENT_PROVIDER_LIST.includes(paymentProvider);
};
