import { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { api } from '@api/index';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { APP_ROUTES } from '@lib/route/constants';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography, useTheme } from '@mui/material';
import { Button } from '@ui/buttons/Button';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { PriceFormat } from '@ui/price/PriceFormat';

interface Props {
  accountId: string;
  isLoading?: boolean;
}

export const InvoiceCard: FC<Props> = ({ accountId, isLoading }) => {
  const { tenant } = useTenantProps();
  const { t } = useTranslation();
  const theme = useTheme();
  const specificBackgroundColor =
    tenant.theme?.specifics?.authHomePageCard?.backgroundColor;
  const router = useRouter();
  const {
    data: accountUpcomingInvoiceAmount,
    isLoading: isLoadingUpcomingInvoiceAmount,
  } = useApiQuery(
    () =>
      api.ocbDigital.invoice.getAccountUpcomingInvoiceAmount(tenant, accountId),
    {
      enabled: !!accountId,
      queryKey: [CACHE_KEYS.accountUpcomingInvoiceAmount(accountId)],
    },
  );

  return (
    <DefaultCard
      title={t('home:auth.invoice.title')}
      noBorder={false}
      button={<Button>{t('home:auth.invoice.payButton')}</Button>}
      contentSx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <DefaultCard
        noBorder={false}
        bgcolor={specificBackgroundColor || '#E8F5FF'}
        borderColor={theme.palette.primary.main}
        height="220px"
      >
        {isLoading || isLoadingUpcomingInvoiceAmount ? (
          <CenteredLoader />
        ) : (
          <Box
            fontSize="26px"
            fontWeight={600}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <PriceFormat
              price={accountUpcomingInvoiceAmount?.amount}
              currency={accountUpcomingInvoiceAmount?.currency}
            />
            <Typography fontSize={16}>
              {t('home:auth.invoice.currentUnbilledAmount')}
            </Typography>
          </Box>
        )}
      </DefaultCard>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '16px',
        }}
        variant="outlined"
        color="secondary"
        size="large"
        endIcon={<KeyboardArrowRightIcon />}
        onClick={() => router.push(APP_ROUTES.PROFILE.INVOICES.path)}
      >
        {t('home:auth.invoice.invoicesButton')}
      </Button>
    </DefaultCard>
  );
};
