import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'next-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { Button } from '@ui/buttons/Button';

interface Props extends Omit<DialogProps, 'onClose' | 'content'> {
  title: string;
  description: string;
  onSubmit: (...args) => void;
  onCancel: () => void;
  onBackdropClick?: () => void;
  cancelText?: string;
  submitText?: string;
  isLoading?: boolean;
  onExited?: () => void;
  content?: ReactNode | undefined;
}

export const AlertDialog: FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    title,
    open = false,
    description,
    isLoading,
    content,
    onBackdropClick,
    onCancel,
    onSubmit,
    cancelText = t('common:cancel'),
    submitText = t('common:proceed'),
    ...rest
  } = props;

  delete rest.onExited;

  return (
    <Dialog
      open={open}
      onClose={onBackdropClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          {cancelText}
        </Button>
        <Button onClick={onSubmit} autoFocus loading={isLoading}>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
