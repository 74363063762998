import { FC } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  OnApproveActions,
  OnApproveData,
  OnCancelledActions,
  PayPalButtonsComponentOptions,
} from '@paypal/paypal-js/types/components/buttons';

import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { PayPalPaymentButton } from './PayPalPaymentButton';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { api } from '@api/index';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useAuth } from '@lib/auth/AuthContext';
import { useApiMutation } from '@lib/fetch/useApiMutation';
import { getEnv } from '@lib/env/getEnv';
import { useTenantById } from '@lib/tenants/useTenantById';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { toast } from 'react-toastify';
import { formatGenericError } from '@lib/fetch/errors';
import { useTranslation } from 'next-i18next';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';

interface Props {
  orderId: string;
  onPaymentCancel?: PayPalButtonsComponentOptions['onCancel'];
  onPaymentApprove?: PayPalButtonsComponentOptions['onApprove'];
  onClose(): void;
}

export const PayPalPaymentCard: FC<Props> = ({
  orderId,
  onPaymentCancel,
  onPaymentApprove,
  onClose,
}) => {
  const { isAuthenticated } = useAuth();
  const { tenant } = useTenantProps();
  const { t } = useTranslation();
  const env = getEnv();
  const tenantId = tenant.config.setup.id[env.client.appEnv];
  const paypalPublicKey =
    tenant.config.setup.paypalPublicKey[env.client.appEnv];
  const { data: tenantById, isLoading: isLoadingTenantById } = useTenantById(
    CACHE_KEYS.tenantById,
  );
  const { data: order, isLoading: loadingOrder } = useApiQuery(
    () =>
      isAuthenticated
        ? api.ocbDigital.order.getOrder({ orderId: orderId }, tenant)
        : api.ocbDigital.orderAuthless.getOrder({ orderId: orderId }, tenant),
    { enabled: !!orderId, queryKey: CACHE_KEYS.order(orderId) },
  );

  const capturePaymentCb = useApiMutation(capturePayment);

  const payPalOrderId = order?.orderPayment?.clientSessionId;

  if (loadingOrder || isLoadingTenantById) return <CenteredLoader />;
  if (!payPalOrderId || !orderId || !paypalPublicKey) {
    return <ErrorPlaceholder />;
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId: paypalPublicKey,
        currency: tenantById?.currency,
        components: 'buttons',
      }}
    >
      {capturePaymentCb.isPending ? (
        <CenteredLoader />
      ) : (
        <PayPalPaymentButton
          onPaymentCancel={handleCancelPayment}
          onPaymentApprove={handlePaymentApprove}
          payPalOrderId={payPalOrderId}
        />
      )}
    </PayPalScriptProvider>
  );

  async function capturePayment(): Promise<void> {
    try {
      if (!tenantId) throw new Error(t('common:errors.tenantIdNotDefined'));
      isAuthenticated
        ? api.ocbDigital.payment.capturePayment({ orderId }, tenant)
        : api.ocbDigital.paymentAuthless.capturePayment(
            { orderId, tenantId },
            tenant,
          );
    } catch (error) {
      toast.error(formatGenericError(error));
    }
  }

  async function handlePaymentApprove(
    data: OnApproveData,
    actions: OnApproveActions,
  ) {
    await capturePaymentCb.mutateAsync();

    if (onPaymentApprove) await onPaymentApprove(data, actions);
    onClose();
  }

  function handleCancelPayment(
    data: Record<string, unknown>,
    actions: OnCancelledActions,
  ) {
    if (onPaymentCancel) onPaymentCancel(data, actions);
  }
};
